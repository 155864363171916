<template>

  <div>

    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <!--            <label>显示</label>-->
            <!--            <v-select-->
            <!--              v-model="limit"-->
            <!--              :options="limitOptions"-->
            <!--              :clearable="false"-->
            <!--              class="per-page-selector d-inline-block mx-50"-->
            <!--            />-->
            <!--            <label>条</label>-->
          </b-col>

          <!-- Search -->
          <b-col md="4"></b-col>

          <b-button
              variant="primary"
              @click="create"
              v-if="readonly===0&&(srmReadonly===0||type==1)"
          >
            <span class="text-nowrap">添加</span>
          </b-button>
        </b-row>

      </div>

      <b-table
          ref="refListTable"
          class="position-relative"
          :items="searchList"
          responsive
          hover
          small
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="orderBy"
          show-empty
          empty-text="未找到记录"
          :sort-desc.sync="isSortDirDesc"
          v-model="list"
      >

        <template v-slot:custom-foot="data">
          <b-tr>
            <b-td :colspan="2" variant="primary">
              <span><strong>实收小计</strong></span>
            </b-td>
            <b-td :colspan="2" variant="primary">
              <span><strong>{{allReceiveTotal}}</strong></span>
            </b-td>
          </b-tr>
        </template>

        <!-- Column: Type -->
        <template #cell(receive_method)="data">
          <v-select
              :options="type==2?(userIsSettle?getCodeOptions('statement_pay_method').filter(e=>e.value==1||e.value==2||e.value==3):getCodeOptions('statement_pay_method').filter(e=>e.value==1)):getCodeOptions('statement_pay_method_online')"
              :clearable="true"
              style="width: 180px"
              v-model="data.item.receiveMethod"
              @input="changeSelect(data.item,$event,'receive_method')"
              class="select-size-sm"
              placeholder="请选择收款方式"
              :disabled="srmReadonly!==0&&type!=1"
          />
        </template>

        <template #cell(receive_account)="data">
          <v-select
              :options="bankList"
              :clearable="true"
              style="width: 250px"
              v-model="data.item.ext.receiveAccount"
              @input="changeSelect(data.item,$event,'receive_account')"
              class="select-size-sm"
              placeholder="请选择收款账号"
              :disabled="srmReadonly!==0&&type!=1"
          />
        </template>

        <template #cell(receive_total)="data">
          <b-form-input
              size="sm"
              v-model="data.item.receive_total"
              type="number"
              style="width: 80px"
              @blur="save(data.item)"
              @change="changeReceiveTotal(data.item,$event)"
              :readonly="readonly!==0"
          />
        </template>

        <!-- Column: State -->
        <template #cell(state)="data">
          <b-link
              @click="changeState(data.item)"
          >
            <b-badge
                pill
                :variant="`light-${getCodeColor('state', data.item.state)}`"

            >
              {{ getCodeLabel('state', data.item.state) }}
            </b-badge>
          </b-link>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-row>
            <b-link @click="save(data.item)">
              <feather-icon
                  icon="SaveIcon"
                  size="16"
                  class="align-middle text-body"
              />
            </b-link>
            <b-link>
              <feather-icon @click="del(data.item)"
                            icon="XSquareIcon"
                            size="16"
                            class="align-middle text-body"
                            v-if="srmReadonly===0||type==1"
              />
            </b-link>
          </b-row>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
<!--          <b-col-->
<!--              cols="12"-->
<!--              sm="6"-->
<!--              class="d-flex align-items-center justify-content-center justify-content-sm-end"-->
<!--          >-->

<!--            <b-pagination-->
<!--                v-model="start"-->
<!--                :total-rows="listTotals"-->
<!--                :per-page="limit"-->
<!--                first-number-->
<!--                last-number-->
<!--                class="mb-0 mt-1 mt-sm-0"-->
<!--                prev-class="prev-item"-->
<!--                next-class="next-item"-->
<!--            >-->
<!--              <template #prev-text>-->
<!--                <feather-icon-->
<!--                    icon="ChevronLeftIcon"-->
<!--                    size="18"-->
<!--                />-->
<!--              </template>-->
<!--              <template #next-text>-->
<!--                <feather-icon-->
<!--                    icon="ChevronRightIcon"-->
<!--                    size="18"-->
<!--                />-->
<!--              </template>-->
<!--            </b-pagination>-->

<!--          </b-col>-->

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {ref, onUnmounted, watch} from '@vue/composition-api'
import {avatarText, toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor} from '@core/utils/filter'
import statementreceivemethodUseList from './statementreceivemethodUseList'
import statementreceivemethodStore from './statementreceivemethodStore'
import {useToast} from "vue-toastification/composition";
import statementreceiveitemStore from "@/views/apps/statementreceiveitem/statementreceiveitemStore";
import { getUserData } from '@/auth/utils'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  props: {
    receiveId: {
      type: Number,
    },
    bankList: {

    },
    statementreceive: {

    },
    sumTotal:{
      type:Number
    },
    readonly: {
      type: Number,
      default: 0,
    },
    type: {
      default: 0,
    },
    srmReadonly: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      list:[],
      userIsSettle:false,
    }
  },
  methods: {
    changeState: function (data) {
      const state = 1 - data.state
      store.dispatch('statementreceivemethod/state', {id: data.id, state: state}).then(res => {
        data.state = state
      })
    },
  },
  watch:{
    allReceiveTotal:{
      handler(){
        this.$emit("table",this.allReceiveTotal)
      }
    },
    sumTotal:{
      handler(newValue,oldValue){

      }
    }
  },
  setup(props) {
    const toast = useToast()
    // Register module
    if (!store.hasModule('statementreceivemethod')) store.registerModule('statementreceivemethod', statementreceivemethodStore)
    if (!store.hasModule('statementreceiveitem')) store.registerModule('statementreceiveitem', statementreceiveitemStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('statementreceivemethod')) store.unregisterModule('statementreceivemethod')
      if (store.hasModule('statementreceiveitem')) store.unregisterModule('statementreceiveitem')
    })

    const create = function () {
      store.dispatch('statementreceivemethod/create', {receiveId: props.receiveId}).then(res => {
        if (res.data.code == 0) {
          toast.success('添加成功')
          let list = res.data.data
          for (let i = 0; i < list.length; i++) {
            list[i].typeName = getCodeLabel("statement_cost_type", list[i].type)
          }
          refetchData()
        } else {
          toast.error('添加失败')
        }
      })
    }

    const toFloat = function (params) {
      return parseFloat(params.toFixed(2))
    }

    const save = function (params) {
      if(params.receive_total==undefined||params.receive_total==''){
        return false;
      }
      if(params.receiveMethod==undefined||params.receiveMethod==''){
        alert("请选择收款方式!")
        return false;
      }
      if((params.receive_method != '1') && (params.ext.receiveAccount==undefined||params.ext.receiveAccount=='')){
        alert("请选择收款账号!")
        return false;
      }
      // console.log(1,toFloat(this.statementreceive.ext.remainTotal))
      // console.log(2,toFloat(this.sumTotal))
      // console.log(3,toFloat(this.sumTotal)<toFloat(this.statementreceive.ext.remainTotal))
      // if ((toFloat(this.statementreceive.ext.remainTotal)>=0&&toFloat(this.sumTotal)<0)||(toFloat(this.statementreceive.ext.remainTotal)>=0&&toFloat(this.sumTotal)>toFloat(this.statementreceive.ext.remainTotal))){
      //   toast.error("费用金额不能大于预收金额!")
      //   params.receive_total = 0
      // }
      // if ((toFloat(this.statementreceive.ext.remainTotal)<=0&&toFloat(this.sumTotal)>0)||(toFloat(this.statementreceive.ext.remainTotal)<=0&&toFloat(this.sumTotal)<toFloat(this.statementreceive.ext.remainTotal))){
      //   toast.error("费用金额不能大于预收金额!")
      //   params.receive_total = 0
      // }
      params.type = this.type
      store.dispatch('statementreceivemethod/save', params).then(res => {
        if (res.data.code == 0) {
          toast.success('保存成功')
          refetchData()
          store.dispatch('statementreceiveitem/changeReceiveTotal', {
            id: this.statementreceive.receive_id,
          }).then(res => {
            if (res.data.code == 0) {
              this.$emit("refetchData",res.data.data.receive_total)
            } else {
              toast.error(res.data.data)
            }
          })
        } else {
          toast.error(res.data.data)
          refetchData()
        }
      })
    }

    const del = function (params) {
      if (confirm("确认删除吗?")) {
        store.dispatch('statementreceivemethod/del', {id: params.id, statementId: params.statement_id}).then(res => {
          if (res.data.code == 0) {
            toast.success('删除成功')
            refetchData()
            store.dispatch('statementreceiveitem/changeReceiveTotal', {
              id: this.statementreceive.receive_id,
            }).then(res => {
              if (res.data.code == 0) {
                this.$emit("refetchData",res.data.data.receive_total)
              } else {
                toast.error(res.data.data)
              }
            })
          } else {
            toast.error('删除失败')
          }
        })
      }
    }

    const changeSelect = function (params, event, key) {
      params[key] = event == null ? null : event.value
      if ('receive_method' === key) {
        store.dispatch('statementreceivemethod/getReceiveAccount', {
          receiveId: params.receive_id,
          receiveMethod: params.receive_method,
          companyId: props.statementreceive.company_id,
          warehouseId: props.statementreceive.warehouse_id
        }).then(res => {
          if (res.data.code == 0) {
            params.ext.receiveAccount = res.data.data.account_no
            params.receive_account = res.data.data.account_id
            this.$forceUpdate()
          }
        })
      }
      if ('receive_account' === key) {
        params.ext.receiveAccount = event.label
        params.receive_account = event.value
      }
      this.$forceUpdate()
    }

    const changeReceiveTotal = function (data,params) {
      let price = 0
      for (let i = 0, len = this.list.length; i < len; i++) {
        if(this.list[i].id==data.id){
          this.list[i]['receive_total'] = params
        }
        if(this.list[i]['receive_total'] != undefined && this.list[i]['receive_total'] != ''){
          price+=parseFloat(this.list[i]['receive_total'])
        }
      }
      // console.log(this.allReceiveTotal)
      this.allReceiveTotal = price

      // store.dispatch('statementreceivemethod/sumReceiveTotal', {receiveId:data.receive_id,receiveTotal:params,methodId:data.method_id}).then(res => {
      //   if (res.data.code == 0) {
      //     this.allReceiveTotal = res.data.data
      //   }
      // })
    }

    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      allReceiveTotal,

      // UI
    } = statementreceivemethodUseList(
        {
          receiveId:props.receiveId,
          readonly: props.readonly,
          type:props.type,
        })

    return {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      // UI
      toTime,
      toDate,
      create,
      save,
      del,
      changeSelect,
      changeReceiveTotal,


      allReceiveTotal,
    }
  },
  created() {

    const userData = getUserData()
    this.user = userData
    //结算人员
    let settleArr = getCodeLabel("setting","user_settle")
    this.userIsSettle = settleArr.includes(this.user.user_id)
  }
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
